
import { defineComponent, onMounted, ref, watch } from "vue";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useStore } from "vuex";
import { FeedListModel } from "@/domain/feed/feedList/model/FeedListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const feedList = ref<FeedListModel[]>([]);

    const feedController = store.state.ControllersModule.feedController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!feedController) {
      throw new Error("FeedController is not available in Vuex store.");
    }

    watch(
      () => store.getters.newFood,
      () => {
        if (store.getters.newFood) getFeedList(1);
      }
    );

    const getFeedList = async (page) => {
      isLoading.value = true;

      feedList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const feedListModel: FeedListModel = {
        feed: {},
        page: 1,
        pageSize: pageSize.value,
      };

      feedController
        .feedList(feedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((feed) => {
              totalItems.value = feed.pagination.total;
              currentPage.value = feed.pagination.page;
              pageCount.value = feed.pagination.pageCount;
              feedList.value.push(feed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.FOOD));
      getFeedList(1);
    });

    return {
      PAGE_TITLE_PATH,
      feedList,
      isLoading,
      getFeedList,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
    };
  },
});
